<template>
  <LayoutDossierPromoteurs>
    <template #progress_bar
      ><progress-bar
        section="Bilan financier"
        :progress_section="progress_section.bilan_financier"
      />
    </template>
    <template #current_page>Bilan financier</template>
    <template #body>
      <div>bilan-financier</div>
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>
<script>
import { mapGetters } from 'vuex'
import progress from '@/mixins/dossierPromoteurs/progress'
export default {
  mixins: [progress],

  methods: {
    saveInfos() {
      // sending requests
      //this.$toaster.success("Le dossier a bien été sauvegardé");
    },
  },

  created() {
    this.$store.commit('dossierpromoteur/setProgressSection', {
      section: 'bilan_financier',
      value: 100,
    })
  },

  computed: {
    ...mapGetters({
      progress_section: 'dossierpromoteur/getProgressSection',
    }),
  },
}
</script>
